<template>
  <div class="content">
    <h1>Notes</h1>

    <div class="flex flex-col gap-2">
      <div
        class="
          flex gap-4 justify-between items-center
          bg-gray-100 border border-gray-200 rounded-sm px-4 py-2
        "
      >
        <div class="flex flex-1 flex-col md:flex-row gap-1 md:gap-2 w-50 text-nowrap">
          <button-select
            v-model="note"
            :options="notes"
            size="sm"
            :disabled="savingCount > 0"
            @input="fetchNotes"
            class="flex-wrap"
          >
            <template v-slot="{ option }">
              <div class="flex gap-2 items-center">
                <div v-if="option.is_owner === false" class="text-xs">
                  <i class="fas fa-users"></i>
                </div>
                <div>{{ option.text }}</div>
              </div>
            </template>
          </button-select>
          <b-button
            variant="white"
            size="sm"
            @click="createNote"
            :disabled="savingCount > 0"
          >
            <div class="flex gap-2 items-center">
              <span>New note</span>
              <i class="far fa-plus-square"></i>
            </div>
          </b-button>
        </div>
        <div>
          <div>
            <b-spinner
              v-if="savingCount > 0"
              small
              label="Saving"
              title="Saving"
            ></b-spinner>
            <i
              v-else
              class="fas fa-check text-green-600"
              title="Saved"
            ></i>
          </div>
        </div>
      </div>

      <note
        :id="note"
        @updated="fetchNotes"
        @savingCount="updateSavingCount"
      />
    </div>
  </div>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const Note = () => import('@/components/notes/Note.vue');

export default {
  name: 'Notes',
  components: {
    ButtonSelect,
    Note,
  },
  data() {
    return {
      loadingCount: 0,
      savingCount: 0,
      notes: [],
      note: null,
    };
  },
  methods: {
    updateSavingCount(savingCount) {
      this.savingCount = savingCount;
    },
    updateSelectedNote() {
      if (this.note !== null) {
        const noteIds = this.notes.map((note) => note.value);
        if (noteIds.includes(this.note)) {
          return;
        }
      }
      this.note = this.notes.length === 0 ? null : this.notes[0].value;
    },
    fetchNotes() {
      this.loadingCount++;
      this.$http
        .get('/notes')
        .then((res) => {
          this.notes = res.body.map((note) => ({
            text: note.note_title,
            value: note.id,
            is_owner: note.is_owner,
          }));
          this.updateSelectedNote();
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch notes: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    createNote() {
      const noteTitle = prompt('Enter title', 'Note');
      if (noteTitle === null || noteTitle.trim().length === 0) {
        return;
      }
      this.loadingCount++;
      this.$http
        .post('/notes')
        .send({ note_title: noteTitle })
        .then((res) => {
          this.note = res.body.id;
          this.fetchNotes();
        })
        .catch((err) => {
          this.$toast.error(`Failed to create note: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    this.fetchNotes();
  },
};
</script>

<style lang="scss" scoped>
</style>
